

import { Component, Vue } from "vue-property-decorator";
import Axios from "axios";

import Multiselect from "vue-multiselect";
import { BASE_API_URL } from "@/config";

@Component({ components: { Multiselect } })
export default class addManageRoleComponent extends Vue {
  public roles = [];
  public roleData = [];
  public defaultRolename = "Update Role ";
  public wemloProcessor = [];
  public roleNameExistenceError = null;
  public searchStaff: any = {};
  public brokerList = [];
  public searchObj = null;
  public wemloId = null;
  public taskRoles = [];
  public roleObj = {
    id: {},
    name: {},
    description: {},
    processorId: [],
    tasks: []
  };
  // public async searchRoles(){
  //   try{
  //     this.getWemloStaffList();
  //     if(this.searchStaff=="wemloStaff"){
  //     this.searchObj={id:this.wemloId, name:"Super Admin"}
  //     this.searchRolesType()
  //    }

  //     if(this.searchStaff=="brokerStaff"){
  //     if(this.searchObj!=null && this.searchObj.id==this.wemloId){
  //       return
  //      }

  //      this.searchRolesType()

  //     }

  //     if(this.searchStaff==null){
  //     this.getRoles();
  //     this.searchObj=null
  //     }
  //     }
  //   catch(error){console.log(error)}
  // }
  public async searchRolesType() {
    try {
      await this.getRoles(false);
      this.getWemloStaffList();
      // }
      // if (this.searchObj == null) return;
      // let response = await Axios.post(
      //   BASE_API_URL + "superAdmin/searchRoles",
      //   this.searchStaff,
      //// );
      // this.roles = response.data;
      // if(this.searchStaff=="wemloStaff"){
      // this.searchObj=null
      // }
    } catch (error) {
      console.log(error);
    }
  }

  //Fetching Details from roles collection assigned to roles array
  public async getRoles(mounted) {
    try {
      if (mounted) {
        this.searchStaff.type = "All";
      }
      let response = await Axios.post(
        BASE_API_URL + "superAdmin/getRoles",
        { searchStaff: this.searchStaff });
      this.roles = response.data.roles;
      this.roleData = response.data.roles;
      this.wemloId = response.data.wemloId;
      this.brokerList = response.data.brokerList;
    } catch (error) {
      console.log(error);
    }
  }

  //Fetching Details from Wemlostaff Collection
  public async getWemloStaffList() {
    try {
      let response = await Axios.get(
        BASE_API_URL + "wemloStaff/getWemloStaffList");
      //data are map in wemloProcessor
      if (response.data.data) {
        let wemloProcesss = response.data.data.map(element => {
          return {
            id: element.userId,
            name: element.firstName + " " + element.lastName,
            addedBy: element.addedBy,
            addedByUserType: element.addedByUserType
          };
        });
        if (this.searchStaff.type == "All") {
          this.wemloProcessor = wemloProcesss;
        }
        if (this.searchStaff.type == "wemloStaff") {
          this.wemloProcessor = wemloProcesss.filter(
            a => a.addedByUserType == "Super Admin"
          );
        }
        if (this.searchStaff.type == "brokerStaff") {
          this.wemloProcessor = wemloProcesss.filter(
            e => e.addedByUserType != "Super Admin"
          );
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  //Updating Details in roles collection
  public async updateRole() {
    try {
      //this.taskRoles.map(e=>e.processorId=this.roleObj.processorId);
      let processors = [];
      // if (this.searchStaff.type == "wemloStaff") {
      //   let a = this.taskRoles[0].processorId.filter(
      //     a => a.addedByUserType != "Super Admin"
      //   );

      //   if (a.length > 0) {
      //     processors = a.concat(this.roleObj.processorId);
      //   } else {
      //     processors = this.roleObj.processorId;
      //   }
      // }
      // if (this.searchStaff.type == "brokerStaff") {
      //   let b = this.taskRoles[0].processorId.filter(
      //     a => a.addedBy != this.searchObj.id
      //   );
      //   if (b.length > 0) {
      //     processors = b.concat(this.roleObj.processorId);
      //   } else {
      //     processors = this.roleObj.processorId;
      //   }
      // }
      // if (this.searchStaff.type == "All") {
      //   processors = this.roleObj.processorId;
      // }

      this.taskRoles.forEach(e => (e.processorId = processors));

      if (!this.roleNameExistenceError) {
        let response = await Axios.post(
          BASE_API_URL + "superAdmin/updateRole",
          this.roleObj)
          // this.taskRoles);
        if (response.data.type == "error") {
          this.roleNameExistenceError = response.data.message;
        } else {
          this.$snotify.success("Updated Successfully.");
          // this.searchRoles();
          this.hideModal();
        }
      }
      this.getRoles(false);
    } catch (error) {
      console.log(error);
    }
  }

  //Converting role array to Object type for dispalying data in updateRole Modal
  public async openUpdateRoleModal(i, taskId) {
    //await this.getRoles();
    //this.searchStaff = null;
    //this.searchObj=null
    if (taskId == 7)
      this.taskRoles = this.roleData.filter(
        r => r.id == taskId || r.id == 8 || r.id == 9
      );
    else this.taskRoles = this.roleData.filter(r => r.id == taskId);

    let role = this.roles[i];
    this.roleObj = JSON.parse(JSON.stringify(role));
    this.$modal.show("updateRole");
  }

  //Hiding modal & Emptying All fields
  public hideModal() {
    this.roleObj = {
      id: {},
      name: {},
      description: {},
      processorId: [],
      tasks: []
    };
    this.$modal.hide("updateRole");
    this.roleNameExistenceError = null;
  }

  //Returning 1st letter capital of string inside array after joining All strings
  public getTaskFlow(arr) {
    return arr.join(" -> ");
  }

  async mounted() {
    this.getRoles(true);
    this.getWemloStaffList();
  }
}
